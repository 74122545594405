<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Add City
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>

    <validation-observer ref="addCityFormvalidate">
      <b-form
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
        ref="form">
        <b-row>
          <b-col md="4">
            <b-form-group
              label="City Name"
              label-for="blog-edit-title"
              class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="City Name"
                rules="required"
                >
                <b-form-input id="blog-edit-title" v-model="addCityForm.name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="In-Link Name"
              label-for="blog-edit-slug"
              class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="In-Link Name"
                rules="required"
                >
                <b-form-input
                  id="blog-edit-slug"
                  v-model="addCityForm.link_id"
                  @input="removeSpace($event)"
                  @change="checkLink" />
                  <small class="text-danger">{{ errors[0] }}</small>
                <small class="text-danger" v-if="!validLink">Not Valid</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="In-Slider Order"
              label-for="blog-edit-category"
              class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="In-Slider Order"
                >
                <b-form-input
                  type="number"
                  v-model="addCityForm.in_slider_order"
                  id="in_slider_order" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mb-2">
            <div class="border rounded p-2">
              <h4 class="mb-1">Icon image</h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row">
                <div class="d-inline-block">
                <b-media-aside>
                  <b-img
                    ref="refPreviewEl"
                    :src="Tabimage"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0" />
                </b-media-aside>
                <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                class="rounded ml-1 mt-2 text-center"
                @click="refPreviewEl.src = Tabimage
                addCityForm.icon_image=''
                ">
                Remove Image
              </b-button>
            </div>
            <b-media-body>
              <b-card-text class="mt-1"> </b-card-text>
              <div class="d-inline-block  mt-1">
                    <b-form-group
                      label="Icon Image "
                      label-for="blog-edit-title"
                      class="mb-2">
                      <validation-provider
                        #default="{ errors }"
                        name="Icon Image "
                        >
                        <b-form-file
                          ref="refInputEl"
                          v-model="addCityForm.icon_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRenderer" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="icon image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2">
                      <b-form-input
                        id=" alt_icon_image"
                        v-model="addCityForm.alt_icon_image" />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
            <div class="border rounded p-2">
              <h4 class="mb-1">Tab image</h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row">
                <div class="d-inline-block">
                <b-media-aside>
                  <b-img
                    ref="refPreviewE2"
                    :src="Tabimage"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0" />
                </b-media-aside>
                
                <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                class="rounded ml-1 mt-2 text-center"
                @click="refPreviewE2.src = Tabimage
                addCityForm.tab_image=''">
                Remove Image
              </b-button>
            </div>
            <b-media-body>
              <b-card-text class="mt-1"> </b-card-text>
              <div class="d-inline-block  mt-1">
                    <b-form-group
                      label="Tab Image "
                      label-for="blog-edit-title"
                      class="mb-2">
                      <validation-provider
                        #default="{ errors }"
                        name="Tab Image "
                        >
                        <b-form-file
                          ref="refInputE2"
                          v-model="addCityForm.tab_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Tab Image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2">
                      <b-form-input
                        id="alt-image"
                        v-model="addCityForm.alt_tab_image" />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>
          <b-col cols="12" class="mt-50">
            <b-button
              v-if="!isLoading"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save">
              Save Changes
            </b-button>

            <b-button v-if="isLoading" variant="primary" disabled class="mr-1">
              Loading...
              <b-spinner small />
              <span class="sr-only"></span>
            </b-button>
        
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref, reactive } from "@vue/composition-api";
import store from "@/store";
import { GMapMap } from "vue2-google-maps";

import Vue from "vue";
export default {
  setup(props, { emit }) {
    // const store = useStore();
    const center = ref({ lat: 51.093048, lng: 6.84212 });
    const refInputEl = ref(null);
    const refInputE2 = ref(null);
    const refPreviewEl = ref(null);
    const isLoading = ref(false);
    const refPreviewE2 = ref(null);
    const Tabimage = ref("media/svg/files/blank-image.svg");
    const validLink = ref(true);
    const checkLink = (link) => {
      const formData = new FormData();

      formData.append("slug", link);
      store.dispatch("courses/checkLink", formData).then((response) => {
        console.log("lin", response.data.valid);
        validLink.value = response.data.valid;
      });
    };
    const removeSpace = (target) => {
      addCityForm.link_id = target.toLowerCase() // LowerCase
        .replace(/\s+/g, "-") // space to -
        .replace(/&/g, ``) // & to and
        .replace(/--/g, `-`)
        .replace(/[.,]/g, `-`)
        .replace(/[_]/g, ``)
        .replace(/[!''"":=]/g, '')
        .replace(/[!?%$^~*#()+]/g, ``)
    };
    const categoriesList = ref([]);
    store.dispatch("categories/AllCategory").then((response) => {
      console.log("response", response.data.data);
      categoriesList.value = response.data.data;
    });
    const addCityForm = reactive({
      save_as: "",
      name: "",
      link_id: "",
      tab_image: [],
      icon_image: [],
      alt_tab_image: "",
      alt_icon_image: "",
      duration: "",
      in_slider_order: "",
      category_id: "",
    });
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64;
      }
    );
    const form = ref();
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      (base64) => {
        refPreviewE2.value.src = base64;
      }
    );
    const addCityFormvalidate = ref();
    const marker = ref({ position: { lat: 24.4, lng: 46.71667 } });
    const handleMapClick = (e) => {
      marker.value.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    };
    const save = () => {
      console.log(addCityForm);
      const formData = new FormData();

      if (validLink.value == true) {
        addCityFormvalidate.value.validate().then((success) => {
          if (success) {
            formData.append("name", addCityForm.name);
            formData.append("icon_image", addCityForm.icon_image);
            formData.append("name", addCityForm.name);
            formData.append("link_id", addCityForm.link_id);
            formData.append("tab_image", addCityForm.tab_image);
            formData.append(" icon_image", addCityForm.icon_image);
            formData.append("alt_tab_image", addCityForm.alt_tab_image);
            formData.append("alt_icon_image", addCityForm.alt_icon_image);
            formData.append("in_slider_order", addCityForm.in_slider_order);
            console.log("formData", formData);
            isLoading.value = true;
            store
              .dispatch("cities/addcity", formData)
              .then((response) => {
                isLoading.value = false;
                form.value.reset();
                refPreviewEl.value.src = "media/svg/files/blank-image.svg";
                refPreviewE2.value.src = "media/svg/files/blank-image.svg";
                Vue.swal({
                  title: "City Added ",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              })
              .catch((error) => {
                isLoading.value = false;
                Vue.swal({
                  title: "",
                  text: `${error.response.data.message}`,
                  icon: "error",
                  confirmButtonText: "ok",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              });

            console.log("alt_icon_image", addCityForm);
          }
        });
      } else {
        Vue.swal({
          title: "",
          text: `Please Enter Valid Link`,
          icon: "error",
          confirmButtonText: "ok",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    };
    return {
      refInputEl,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      addCityForm,
      inputImageRenderer,
      addCityFormvalidate,
      categoriesList,
      form,
      handleMapClick,
      center,
      save,
      removeSpace,
      validLink,
      checkLink,
      required,
      isLoading,
      email,
    };
  },

  components: {
    BCard,
    BMedia,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
  },

  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style>
.mb-2.col-12 {
  margin: -10px !important;
}
.border.rounded.p-2 {
  margin: 14px 9px !important;
}
.mt-50.col-12 {
  margin: 0px;
}


</style>
